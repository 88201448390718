jQuery(document).ready(function ($) {
	cookie();
	$(window).on('scroll', scroll_cookie).trigger('scroll');
});

function cookie() {
	$('.cookies .btn').on('click', function() {
		Cookies.set('nutricia_allow_cookies', 1, { expires: 365 * 10 });
		$(this).closest('.cookies').slideUp('fast', function() {
			set_margin();
			$(this).remove();
		});
	});
}

var $cookies = $('.cookies');

function scroll_cookie() {
	if($(window).scrollTop() == 0) {
		$cookies.slideDown('fast', set_margin);
	}
	else if($cookies.css('display') != 'none')
		$cookies.slideUp('fast', set_margin);
}

function set_margin() {
	$('.site-wrapper').css('margin-top', $('#container-header').height() + 'px');
}