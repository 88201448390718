if ($('#map').length > 0) {
	$(function () {
		map_init();
	});

	function map_init() {
		var options = {
			zoom: 6,
			center: new google.maps.LatLng(52.06600028274635, 19.00634765625),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			//scaleControl:           false,
			navigationControl: false,
			streetViewControl: false,
			mapTypeControl: false,
			callback: $('.google-maps').trigger('callback')
		};

		gmap = new google.maps.Map(document.getElementById('map'), options);

		var infoWindow = new google.maps.InfoWindow({
			maxHeight: 600
		});

		var createMarker = function (lat, lng, content, id) {

			var marker = new google.maps.Marker({
				map: gmap,
				position: new google.maps.LatLng(lat, lng, content),
				icon: defaultIcon,
				draggable: false,
				cityId: id
			});

			google.maps.event.addListener(marker, 'click', function () {
				if (recentlyActiveMarker !== null) {
					recentlyActiveMarker.setIcon(defaultIcon);
				}
				recentlyActiveMarker = this;
				this.setIcon(activeIcon);
				infoWindow.setContent(content);
				infoWindow.open(gmap, marker);
			});

			gmarkers.push(marker);
		};
		$("#citiesList .cities-data li").each(function () {
			createMarker($(this).data("lat"), $(this).data("lng"), $(this).find(".label").first().html(), $(this).data("id"));
			var markerPreload = new google.maps.Marker({
				map: gmap,
				position: new google.maps.LatLng(52.06600028274635, 19.00634765625, ''),
				icon: activeIcon,
				draggable: false
			});
			markerPreload.setMap(null);
		});
		gmap.setOptions({styles: mapstyles});
	};
	var defaultIcon = {
		url: '/images/map-icon-default.png',
		size: new google.maps.Size(36, 45),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(16, 43)
	};
	var activeIcon = {
		url: '/images/map-icon-active.png',
		size: new google.maps.Size(36, 45),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(16, 43)
	};
	var gmarkers = [];
	var gmap = null;
	var recentlyActiveMarker = null;
	var mapstyles = [
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#9283be"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"saturation": -100
				},
				{
					"lightness": 45
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"color": "#9283be"
				},
				{
					"visibility": "on"
				}
			]
		}];
}