jQuery(document).ready(function ($) {
    applyListeners();
});

function applyListeners() {
    var panelItems = $('.interactive-panel .panel-navigation ul li');
    panelItems.click(function(){
        toggleCurrent(this);
    }).mouseenter(function(){
        toggleCurrent(this);
    });
};

function toggleCurrent(element) {
    if (!$(element).hasClass('active')) {
        $(element).addClass('active');
        $(element).parent().children('li').not(element).removeClass('active');
        loadContent(element);
    }
}

function loadContent(element) {
    var targetElement = ".panel-content ." + $(element).data('display');
    $(".panel-content .display-content").not(targetElement).slideUp('fast', function() {
        $(targetElement).slideDown('slow');
    });
}


