jQuery(document).ready(function ($) {
    $( window ).scroll(function() {
        $('.chart').each(function() {
            if($( this ).data("animated") != "1") {
                if (isScrolledIntoView(this)) {
                    $(this).data( "animated" , '1' );
                    draw_chart(this);
                }
            }
        });
    });
});

function draw_chart(chart) {
    jQuery(chart).easyPieChart({
        easing: 'easeOutBounce',
        scaleColor: false,
        trackColor: '#a2238e',
        barColor: '#f0f0f0',
        lineWidth: 40,
        trackWidth: 39,
        lineCap: 'butt',
        size: 225
    });
}

function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
