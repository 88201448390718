$(function() {
	$('.city-list .btn').on('click', getCurrent);
        lockMouseWheelScroll();
});
function lockMouseWheelScroll() {
    // $("#citiesLeftList").bind("mousewheel", function() {
    //     return false;
    // });
}
function getCurrent() {
    var arrayLength = gmarkers.length;
    for (var i = 0; i < arrayLength; i++) {
        if (gmarkers[i].cityId === $(this).data('id')) {
            new google.maps.event.trigger( gmarkers[i], 'click' );
        }
    }
}
