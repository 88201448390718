$(function(){
	$.scrollUp({
		'scrollText':	'<i class="fa fa-arrow-up"></i><span class="hidden-xs">&nbsp;&nbsp;Przejdź do góry</span>'
	});
	$('.go-down').on('click', go_down);
	
	 $.fn.removeOrphans = function(){
	
		function notOrphans($this) {
			var $html = $this.html();
			$html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
			$this.empty().html($html);
		}
		
        if($(this).length > 0) {
			if($(this).children().length > 0) {
				$(this).children().each(function() {
					notOrphans($(this));
				});
			} else {
				notOrphans($(this));
			}
        }
    };
	
	$('.not-orphans').removeOrphans();
});

function go_down() {
	$("html, body").stop().animate({scrollTop: $('.go-down-to').offset().top - 96}, '500', 'swing');
}