function equalHeight(el) {
	setTimeout(function () {

		var maxHeight = 0;
		var $object = $(el);

		$object.css('height', 'auto')
			.each(function () {
				if ($(this).innerHeight() >= maxHeight) {
					maxHeight = $(this).innerHeight();
				}
			});

		$object.each(function () {
			if ($(this).innerHeight() < maxHeight) {
				$(this).css('height', maxHeight);
			}
		});
	}, 100);

}

$(document).ready(function () {

	if ($(window).width() > 768) {
		equalHeight('#keto .tile__item');
	}

	$(window).resize(function () {
		if ($(window).width() > 768) {
			equalHeight('#keto .tile__item');
		}
	});
});