jQuery(document).ready(function ($) {
	
	$('.site-wrapper').css('margin-top', $('#container-header').height() + 'px');
	main_slider();
	news_slider();
	map_center();
	map_hover();
	
});

function main_slider() {
	$('#slider').fadeSlideShow({
        width:				'100%',
        height:				450,
		NextElementText:	'',
		PrevElementText:	''
    });
	
	setTimeout(function() {
		$('#slider').animate({'opacity': 1}, 500);
	}, 500);
	
	$('#slider').on('mouseover', function() {
		$('#fssPlayPause').trigger('click');
	}).on('mouseout', function() {
		$('#fssPlayPause').trigger('click');
	});
}

function main_slider2() {
	var _SlideshowTransitions = [
		{ $Duration: 1200, $Opacity: 2 } //Fade
	];

	var options = {
		$AutoPlay:			true,
		$AutoPlayInterval:	5000,
		$UISearchMode:		1,
		$FillMode:			2,

		$SlideshowOptions: {                               
			$Class:				$JssorSlideshowRunner$,                 
			$Transitions:		_SlideshowTransitions,            
			$TransitionsOrder:	1,                          
			$ShowLink:			true                         
		},

		$BulletNavigatorOptions: {                               
			$Class:			$JssorBulletNavigator$,                  
			$ChanceToShow:	2,                               
			$AutoCenter:	1,                                 
			$Steps:			1,                                      
			$Lanes:			1,                                      
			$SpacingX:		10,                                  
			$SpacingY:		10,                                  
			$Orientation:	1                                 
		}
	};

	var jssor_slider = new $JssorSlider$('slider', options);

	//responsive code	
	function ScaleSlider() {
		var parentWidth = jssor_slider.$Elmt.parentNode.clientWidth;
		if (parentWidth)
			jssor_slider.$ScaleWidth(Math.min(parentWidth, 1920));
		else
			window.setTimeout(ScaleSlider, 30);
	}
	ScaleSlider();

	$(window).bind("load", ScaleSlider);
	$(window).bind("resize", ScaleSlider);
	$(window).bind("orientationchange", ScaleSlider);
}

function news_slider() {
	$('.newses .news-arrow i').on('click', news_arrow_click);
}

function news_arrow_click() {
	var $active = $('.news-list .news.active');
	if($(this).parent().hasClass('news-left')) {
		$active.removeClass('active').fadeOut('medium', function() {
			if($active.prev().length == 1)
				$active.prev().fadeIn('medium', function() {
					$(this).addClass('active');
				});
			else
				$active.siblings().last().fadeIn('medium', function() {
					$(this).addClass('active');
				});
		});
	} else {
		$active.removeClass('active').fadeOut('medium', function() {
			if($active.next().length == 1)
				$active.next().fadeIn('medium', function() {
					$(this).addClass('active');
				});
			else
				$active.siblings().eq(0).fadeIn('medium', function() {
					$(this).addClass('active');
				});
		});
	}
}

function map_center() {
	$(window).on('resize', function() {
		$('.cities .map-container').addClass('centered').css('height', $('.cities .city-list').height() + 'px');
	}).trigger('resize');
}

function map_hover() {
	$('.map-image .map-cities li').on('mouseover', function() {
		var id = $(this).attr('data-id');
		$('.map-image .map-cities li[data-id="' + id + '"]').siblings().removeClass('hovered');
		$('.city-list .city').removeClass('hovered');
		$('.city-list .city[data-id="' + id + '"]').addClass('hovered');
		$('.city-list .city:focus').trigger('blur');
	}).on('mouseout', function() {
		var id = $(this).attr('data-id');
		$('.city-list .city[data-id="' + id + '"]').removeClass('hovered');
	}).on('click', function() {
		var id = $(this).attr('data-id');
		$('.map-image .map-cities li').removeClass('hovered');
		$('.map-image .map-cities li[data-id="' + id + '"]').addClass('hovered');
		$("html, body").animate({ scrollTop: 
			$('.city-list .city[data-id="' + id + '"]').offset().top - $('#container-header').outerHeight() - 20 }, 500, function() {
				$('.city-list .city[data-id="' + id + '"]').trigger('focus');
			});
	});
	
	$('.city-list .city').on('mouseover', function() {
		var id = $(this).attr('data-id');
		$('.map-image .map-cities li').removeClass('hovered');
		$('.map-image .map-cities li[data-id="' + id + '"]').addClass('hovered');
		$('.city-list .city:focus').trigger('blur');
	}).on('mouseout', function() {
		var id = $(this).attr('data-id');
		$('.map-image .map-cities li[data-id="' + id + '"]').removeClass('hovered');
	});
}